<template>
    <div class="page" v-title data-title="首页">
        <div style="float: right; margin-right: 3%; margin-top: -3%">
            <i v-on:click="addDevice" class="iconfont icon-yuanquanjiahao" style="font-size: 24px; color: rgba(0,0,0,.8);"></i>
        </div>
        <div class="weui-cells__title" style="padding-right: 0px">
            <span style="font-size: 16px">我的设备</span>
        </div>
        <div v-for="item in list" :key="item.deviceNo" v-on:click="entryPanel(item)" class="weui-cells" style="margin: 10px 10px; border-radius:5%;">
            <div class="weui-cell" style="padding: 25px 15px;">
                <div style="width: 28px; height: 28px; border-radius: 5%; background-color: #ECEFF1; line-height: 28px;">
                    <i class="iconfont icon-wifi" style="font-size: 28px; color: rgba(0,0,0,.5);"></i>
                </div>
                <div class="weui-cell__bd" style="margin-left: 10px;">
                    <p v-if="item.deviceName">{{ item.deviceName }}</p>
                    <p v-else>{{ item.deviceModel }}</p>
                </div>
                <div v-on:click.stop="setupDevice(item)" style="float: right; width: 60px; height: 30px; border-radius: 5%; background-color: #ECEFF1; line-height: 32px; text-align: center;">
                    <div style="color: rgba(0,0,0,.5);">配置</div>
                </div>
            </div>
        </div>
        <div v-show="noDevice" style="position:absolute; top: 35%; left: 50%; transform: translate(-50%, -50%); text-align: center">
            <svg class="icon" aria-hidden="true" style="font-size: 90px">
                <use xlink:href="#icon-zanwushebei"></use>
            </svg>
            <div style="margin: 10% auto; color: rgba(0,0,0,0.5);">暂无设备</div>
            <div v-on:click="addDevice" style="margin: auto; width: 120px; height: 35px; border-radius: 50px; line-height: 35px; background-color: orangered">
                <span style="color: #FFFFFF;">添加设备</span>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "@/utils/http";

    export default {
        name: "Awgels_Index",
        data() {
            return {
                list: [],
                noDevice: false
            }
        },
        mounted() {
            http.get('/api/device/list', {}, res => {
                this.list = res.data;
                // 判断是否有绑定设备
                if (!this.list || this.list.length == 0) {
                    this.noDevice = true;
                }
            });
        },
        methods: {
            addDevice() {
                this.$router.push({
                    name: 'Awgels_Device'
                })
            },
            entryPanel(item) {
                this.$router.push({
                    name: 'Awgels_Panel',
                    params: {
                        deviceNo: item.deviceNo
                    }
                })
            },
            setupDevice(item) {
                this.$router.push({
                    name: 'Awgels_Device',
                    params: {
                        deviceNo: item.deviceNo
                    }
                })
            }
        }
    }
</script>
